
import {db} from '@/firebase';
import {Character, JDR, User} from '@/types/Interfaces';
import {get, ref, set} from 'firebase/database';
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class CreateGame extends Vue {

    //list of existing jdrs
    jdrs = [
        {value: JDR.ParceDomine, text: 'Parce Domine'},
        {value: JDR.DameBlanche, text: 'Dame Blanche'},
        {value: JDR.Azuria, text: 'Azuria'},
        {value: JDR.Kimakaan, text: 'Kimakäan'},
        {value: JDR.LandOfTheDread, text: 'Land Of The Dread'},
        {value: JDR.ContesMacabres, text: 'Contes Macabres'}
    ];
    //list of existing MJs
    mjs: User[] = []

    //v-models
    type = this.jdrs[0];
    mj = '';
    players: Character[] = [];

    async created(): Promise<void> {
        //set this.users to all users that are MJ
        this.mjs = await get(ref(db, `/users`)).then((snapshot) => {
            return Object.values(snapshot.val());
        });
        this.mjs = this.mjs.filter(user => user.isMJ);
        this.mj = this.mjs[0].id;
    }

    async createGame(): Promise<void> {
        const id = await this.getID();
        this.players.map(player => player.gameId = id);
        const game = {
            id: id,
            mjId: this.mj,
            type: this.type.value,
            name: this.type.text,
            npcCount: 0,
            groups: {
                "0" : "",
            },
            fake: {
                "activated": false,
                "value": 50, 
            },
            map: "no",
        };
        const newGameRef = ref(db, `/jdrs/${game.id}`);
        await set(newGameRef, game);
    }

    /**
     * Returns the next available ID for a game
     */
    async getID(): Promise<string> {
        return await get(ref(db, `/jdrs`)).then((snapshot) => (Object.values(snapshot.val()).length + 10).toString());
    }

    addPlayer(): void {
        const player: Character = {
            gameId: '-1',
            category: '',
            playerId: '',
            isDisplayed: true,
            isNPC: false,
            name: '',
            characteristics: [],
            stats: [],
            informations: [],
            rolls: [],
        };
        this.players.push(player);
    }
}
