
import {Component, Prop, Vue} from 'vue-property-decorator';
import {get, ref, set} from 'firebase/database';
import {db} from '@/firebase';
import {IRoll} from '@/types/Roll';
import {Game} from '@/types/Interfaces';
import {PadoRoll} from '@/types/PadoRoll';
import PadoStatsComponent from '@/components/ParceDomine/PadoStatsComponent.vue';
import {NormalRoll} from '@/types/NormalRoll';
import AzrPlayerInventoryComponent from "@/components/Azuria/AzrPlayerInventoryComponent.vue";
import CMStatsComponent from "@/components/ContesMacabres/CMStatsComponent.vue";
import KimSpellsComponent from "@/components/Kimakaan/KimSpellsComponent.vue";
import KimPlayerInventoryComponent from "@/components/Kimakaan/KimPlayerInventoryComponent.vue";
import PadoMapComponent from "@/components/ParceDomine/PadoMapComponent.vue";
import CMCharSheetComponent from './CMCharSheetComponent.vue';
import { LotdRoll } from '@/types/LotdRoll';
import { CMRoll } from '@/types/CMRoll';

@Component({
    components: {
        PadoMapComponent,
        KimPlayerInventoryComponent,
        KimSpellsComponent,
        CMStatsComponent,
        AzrPlayerInventoryComponent,
        PadoStatsComponent,
        CMCharSheetComponent,
    },
})
export default class CMPlayerComponent extends Vue {
    @Prop() private game !: Game;

    secret = false;
    modalHealth = false;
    modalRi = false;
    modalHealthText = '';
    modalRiText = '';

    normalRollText = '';

    
    async addRoll(roll: IRoll): Promise<void> {
        if (!this.game.characters[this.$store.state.user.uid].rolls) {
            this.game.characters[this.$store.state.user.uid].rolls = [];
        }
        this.game.characters[this.$store.state.user.uid].rolls = [roll, ...this.game.characters[this.$store.state.user.uid].rolls];
        if (this.game.characters[this.$store.state.user.uid].rolls.length > 20) this.game.characters[this.$store.state.user.uid].rolls.pop();
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}/rolls`), this.game.characters[this.$store.state.user.uid].rolls);
    }

    async addGlobalRoll(roll: IRoll): Promise<void> {
        let globalRolls: IRoll[] | null = null;
        await get(ref(db, `/jdrs/${this.game.id}/rolls`)).then(snap => {
            globalRolls = snap.val();
        });
        if (globalRolls === null) globalRolls = [];
        globalRolls = [roll, ...globalRolls];
        globalRolls = globalRolls.slice(0, 100);
        await set(ref(db, `/jdrs/${this.game.id}/rolls`), globalRolls);
    }

    async roll(stat: string): Promise<void> {
        if (this.game && this.game.characters[this.$store.state.user.uid]) {
            let fake = 0
            let activated = false;
            await get(ref(db, `/jdrs/${this.game.id}/fake`)).then(snap => {
                const ret = snap.val();
                fake = ret.value;
                activated = ret.activated;
            });

            const roll: CMRoll = new CMRoll(stat, this.game.characters[this.$store.state.user.uid].name);
            let modifier = 0;
            await roll.roll(this.game.characters[this.$store.state.user.uid], this.secret, this.game.fake, modifier);
            await this.addRoll(roll);
            await this.addGlobalRoll(roll);
        }
    }

    async rollNormal(): Promise<void> {
        const roll = new NormalRoll(this.secret, this.game.type, this.game.characters[this.$store.state.user.uid].name);
        roll.roll(this.normalRollText);
        await this.addRoll(roll);
        await this.addGlobalRoll(roll);
    }

    async useAbility(name: string): Promise<void> {
        await (set(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}`), this.game.characters[this.$store.state.user.uid]));
        const abilityRoll = new PadoRoll(name, this.game.characters[this.$store.state.user.uid].name);
        abilityRoll.critic = false;
        abilityRoll.passed = true;
        abilityRoll.rolled = 0;
        abilityRoll.text = name;
        abilityRoll.setCSSClass();
        await this.addGlobalRoll(abilityRoll);
        await this.addRoll(abilityRoll);
    }
}
