
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Game, Inventory, InventoryItem} from '@/types/Interfaces';
import {PadoRoll} from '@/types/PadoRoll';
import {get, ref, set} from 'firebase/database';
import {db} from '@/firebase';
import {IRoll} from '@/types/Roll';
import PadoStatsComponent from '@/components/ParceDomine/PadoStatsComponent.vue';
import CMStatsComponent from "@/components/ContesMacabres/CMStatsComponent.vue";
import KimPlayerInventoryComponent from "@/components/Kimakaan/KimPlayerInventoryComponent.vue";
import { LotdRoll } from '@/types/LotdRoll';
import { CMRoll } from '@/types/CMRoll';

@Component({
    components: {KimPlayerInventoryComponent, CMStatsComponent, PadoStatsComponent},
})
export default class CMNPCComponent extends Vue {
    @Prop() private game !: Game;
    @Prop() private id !: number;
    @Prop() private secret !: boolean;

    modalInventory = false;
    modalAddItem = false;
    modalCreateName = '';
    modalCreateIsWeapon = false;
    modalCreateAmmunition = 0;
    modalCreateQuantity = 0;

    created(): void {
        document.addEventListener('keyup', (e) => {
            if (e.key === 'Escape') {
                this.modalInventory = false;
                this.modalAddItem = false;
            }
        });
    }

    async addItem(): Promise<void> {
        let inventory: Inventory = this.getInventory();

        //because v-model changes number to string :(
        this.modalCreateAmmunition = parseInt(this.modalCreateAmmunition.toString()) as number;
        this.modalCreateQuantity = parseInt(this.modalCreateQuantity.toString()) as number;

        const item: InventoryItem = {
            name: this.modalCreateName,
            quantity: this.modalCreateQuantity as number,
            isWeapon: this.modalCreateIsWeapon,
            ammunition: this.modalCreateAmmunition as number,
        };
        inventory.items ? inventory.items.push(item) : inventory.items = [item];
        await this.setInventory(inventory);
        this.modalAddItem = false;
        this.modalCreateName = '';
        this.modalCreateIsWeapon = false;
        this.modalCreateAmmunition = 0;
        this.modalCreateQuantity = 0;
    }

    async changeQuantity(id: number, modifier: number): Promise<void> {
        const inventory = this.getInventory();
        const item = inventory.items[id];
        if (item.isWeapon ? item.ammunition + modifier >= 0 : item.quantity + modifier >= 0) {
            item.isWeapon ? item.ammunition += modifier : item.quantity += modifier;
            await this.setInventory(inventory);
        }
    }

    async removeItem(item: InventoryItem): Promise<void> {
        let inventory: Inventory = this.getInventory();
        inventory.items = inventory.items.filter(i => i !== item);
        await this.setInventory(inventory);
    }

    getInventory(): Inventory {
        //@ts-ignore
        return this.game.characters[this.id].inventory;
    }

    async setInventory(inventory: Inventory): Promise<void> {
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}/inventory`), inventory);
    }

    async addRoll(roll: IRoll): Promise<void> {
        if (!this.game.characters[this.id].rolls) {
            this.game.characters[this.id].rolls = [];
        }
        this.game.characters[this.id].rolls = [roll, ...this.game.characters[this.id].rolls];
        if (this.game.characters[this.id].rolls.length > 20) this.game.characters[this.id].rolls.pop();
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.id}/rolls`), this.game.characters[this.id].rolls);
    }

    async addGlobalRoll(roll: IRoll): Promise<void> {
        let globalRolls: IRoll[] | null = null;
        await get(ref(db, `/jdrs/${this.game.id}/rolls`)).then(snap => {
            globalRolls = snap.val();
        });
        if (globalRolls === null) globalRolls = [];
        globalRolls = [roll, ...globalRolls];
        globalRolls = globalRolls.slice(0, 100);
        await set(ref(db, `/jdrs/${this.game.id}/rolls`), globalRolls);
    }

    async roll(stat: string): Promise<void> {
        if (this.game && this.game.characters[this.id]) {
            let fake = 0
            let activated = false;
            await get(ref(db, `/jdrs/${this.game.id}/fake`)).then(snap => {
                const ret = snap.val();
                fake = ret.value;
                activated = ret.activated;
            });

            const roll: CMRoll = new CMRoll(stat, this.game.characters[this.id].name);
            let modifier = 0;
            await roll.roll(this.game.characters[this.id], this.secret, this.game.fake, modifier);
            await this.addRoll(roll);
            await this.addGlobalRoll(roll);
        }
    }

    async useAbility(name: string): Promise<void> {
        const abilityRoll = new PadoRoll(name, this.game.characters[this.id].name);
        abilityRoll.critic = false;
        abilityRoll.passed = true;
        abilityRoll.rolled = 0;
        abilityRoll.text = name;
        abilityRoll.setCSSClass();
        await this.addGlobalRoll(abilityRoll);
        await this.addRoll(abilityRoll);
    }
}

