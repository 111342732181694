import {Roll} from '@/types/Roll';
import {Character, Fake, JDR} from '@/types/Interfaces';
import {ref, set} from 'firebase/database';
import {db} from '@/firebase';
import {Spell} from '@/components/Kimakaan/spells';
import {Element} from '@/components/Kimakaan/ethnies';

export class CMRoll extends Roll {

    private spell?: Spell;

    constructor(stat: string, playerName: string, spell?: Spell) {
        super(stat, JDR.ContesMacabres, playerName);
        if (spell) this.spell = spell;
        else this.spell = new Spell('', '', 0, '', Element.null, '');
    }

    public setText(): void {
        let res = `[${this.spell?.name !== '' ? this.spell?.name : this.stat}] : ${this.rolled}/${this.statValue} `;

        if (this.passed) res += 'Succès ';
        else res += 'Echec ';

        if (this.critic) res += 'critique';

        if (this.passed) res += '!';
        else res += '...';

        this.text = res;
    }

    static randomNumber(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    async roll(character: Character, secret: boolean, fake: Fake, modifier: number): Promise<void> {
        this.statValue = character.characteristics.filter(c => c.name === this.stat)[0].value + modifier;
        const vigueur = character.stats.filter(s => s.name === 'vigueur')[0].current;

        if (fake.activated) {
            const rand = Math.floor(Math.random() * 101)
            if (rand <= fake.value) {
                // fail
                this.rolled = CMRoll.randomNumber(this.statValue + 1, 20);
            } else {
                // success
                this.rolled = CMRoll.randomNumber(1, this.statValue);
            }         
        } else {
            this.rolled = CMRoll.randomNumber(1, 20); //picks a random number between 1 and 20
        }


        // reduce statValue if vigueur is negative
        if (vigueur < 0) {
            const reduce = Math.max(-15, vigueur as number * 3);
            this.statValue = Math.max(1, this.statValue + reduce);
        }

        // update vigueur
        if (this.stat !== 'chance') {
            const vigueurRef = ref(db, `/jdrs/${character.gameId}/characters/${character.playerId}/stats/1/current`);
            await (set(vigueurRef, vigueur as number - 1));
        }

        this.secret = secret;
        //Modifies passed and critic fields according to roll
        this.passed = (this.rolled && this.rolled <= this.statValue) as boolean;
        this.critic = (this.rolled && (this.rolled == 20 || this.rolled == 1)) as boolean;
        this.setText();
        this.setCSSClass();
    }
}
