
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Game} from '@/types/Interfaces';
import {IRoll} from '@/types/Roll';
import {get, ref, set} from 'firebase/database';
import {db} from '@/firebase';
import {NormalRoll} from '@/types/NormalRoll';
import PadoMapComponent from '@/components/ParceDomine/PadoMapComponent.vue';
import PadoMJTurnOrderComponent from '@/components/ParceDomine/PadoMJTurnOrderComponent.vue';
import CMNPCComponent from "@/components/ContesMacabres/CMNPCComponent.vue";

@Component({
    components: {CMNPCComponent, PadoMJTurnOrderComponent, PadoMapComponent},
})

export default class CMMJComponent extends Vue {
    @Prop() private game !: Game;

    secret = false;
    npcs: boolean[] = [];

    normalRollText = '';

    fake = "0"
    fake_activate = false;

    created(): void {
        if (this.game.characters) {
            this.npcs = Array(Object.values(this.game.characters).filter(char => {
                return char.isNPC;
            }).length);
            for (let i = 0; i < this.npcs.length; i++) {
                this.npcs[i] = false;
            }
        }
        if (this.game.fake) {
            //@ts-ignore
            this.fake = this.game.fake.value.toString();
            //@ts-ignore
            this.fake_activate = this.game.fake.activated;
        }
    }

    async rollNormal(): Promise<void> {
        const roll = new NormalRoll(this.secret, this.game.type, 'MJ');
        roll.roll(this.normalRollText);

        let mjRolls: IRoll[] | null = null;
        await get(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}/rolls`)).then(snap => {
            mjRolls = snap.val();
        });
        if (mjRolls === null) mjRolls = [];
        mjRolls = [roll, ...mjRolls];
        mjRolls = mjRolls.slice(0, 25);
        await set(ref(db, `/jdrs/${this.game.id}/characters/${this.$store.state.user.uid}/rolls`), mjRolls);

        let globalRolls: IRoll[] | null = null;
        await get(ref(db, `/jdrs/${this.game.id}/rolls`)).then(snap => {
            globalRolls = snap.val();
        });
        if (globalRolls === null) globalRolls = [];
        globalRolls = [roll, ...globalRolls];
        globalRolls = globalRolls.slice(0, 100);
        await set(ref(db, `/jdrs/${this.game.id}/rolls`), globalRolls);
    }

    getGroups(): { name: string, npcs: any[] }[] {
        const ret: { name: string, npcs: any[] }[] = [];
        if (this.game.groups) {
            this.game.groups.forEach(group => {
                const pnjs = Object.values(this.game.characters).filter(char => {
                    return char.isNPC && char.category === group && char.isDisplayed;
                });
                if (pnjs.length > 0) ret.push({name: group, npcs: pnjs});
            });
        }
        return ret;
    }

    @Watch('game.turnOrder') onTurnOrderChange(): void {
        if (this.game.turnOrder.isStarted && this.game.turnOrder.isActive) {
            this.npcs.forEach((value, index) => {
                if (value) {
                    this.npcs[index] = false;
                }
            });
            this.npcs[parseInt(this.game.turnOrder.characters[0].id)] = true;
        }
    }

    @Watch('fake') async onFakeChange(): Promise<void> {
        await set(ref(db, `/jdrs/${this.game.id}/fake/value`), parseInt(this.fake));
    }
    @Watch('fake_activate') async onFakeActivateChange(): Promise<void> {
        await set(ref(db, `/jdrs/${this.game.id}/fake/activated`), this.fake_activate);
    }
}
